import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import { useAuth } from '../../hooks';
import Spinner from '@/components/ui/Spinner';

const SubscribersPage = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth = useAuth();
  const { user } = auth;

  // Redirect non-admin users
  if (user?.role !== 'admin') {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    const getSubscribers = async () => {
      try {
        const { data } = await axiosInstance.get('/subscribers');
        setSubscribers(data); // Assuming API returns an array of subscribers
        setLoading(false);
      } catch (error) {
        console.log('Error fetching subscribers: ', error);
        setLoading(false);
      }
    };
    getSubscribers();
  }, []);

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const downloadCSV = () => {
    const headers = ['ID,Email,Subscribed At\n'];
    const rows = subscribers.map((subscriber) =>
      `${subscriber._id},"${subscriber.email}","${new Date(subscriber.subscribedAt).toISOString()}"`
    );

    const csvContent = headers + rows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'subscribers.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <Spinner />;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Subscribers List</h2>
        <button
          onClick={downloadCSV}
          className="rounded bg-primary text-white py-2 px-4 hover:bg-green-700"
        >
          Download CSV
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg">
        {subscribers.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscribed At</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {subscribers.map((subscriber, i) => (
                  <tr key={i}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{subscriber.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(subscriber.subscribedAt).toLocaleString('en-US', {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        className="rounded bg-primary py-2 px-4 text-white hover:bg-primary-600"
                        onClick={() => handleEmailClick(subscriber.email)}
                      >
                        Email Subscriber
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="pt-6 text-2xl font-semibold">No subscribers found!</h3>
            <p>It seems like there are no subscribers yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscribersPage;
