import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import PlaceGallery from '@/components/ui/PlaceGallery'; // Assuming you have this component to display images

const SingleContentPage = () => {
  const { id } = useParams(); // Get the content ID from the URL
  const [content, setContent] = useState(null); // State to hold content data
  const [loading, setLoading] = useState(true); // Loading state
  const location = useLocation(); // Get current location
  const navigate = useNavigate(); // Hook to navigate programmatically

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axiosInstance.get(`/content/${id}`);
        setContent(data.content); // Set the content data
        setLoading(false);
      } catch (err) {
        console.error('Error fetching content:', err);
        setLoading(false);
      }
    };

    fetchContent();
  }, [id]);

  const handleGoBack = () => {
    if (window.history.length > 1) {
      window.history.back(); // Go back in browser history if available
    } else {
      navigate('/'); // Optional fallback, redirect to homepage or other route if no history
    }
  };

  if (loading) return <Spinner />; // Show spinner while loading

  if (!content) return <p>Content not found</p>; // Show message if no content is found

  return (
    <div className="mx-auto p-6 bg-white rounded-lg shadow-lg" style={{ marginTop: '100px' }}>
      <div className="text-center">
        <button
          className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white m-3"
          onClick={handleGoBack} // Dynamically go back or redirect
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.5l-7.5-7.5 7.5-7.5" />
          </svg>
          Back to previous page
        </button>
      </div>

      {/* Photos using PlaceGallery */}
      {content.photos && content.photos.length > 0 ? (
        <div className="mb-6 overflow-hidden rounded-lg shadow-md">
          <PlaceGallery place={content} />
        </div>
      ) : (
        <p className="text-gray-500">No photos available for this content</p>
      )}

      {/* Title */}
      <h1 className="text-4xl font-bold text-gray-900 mb-2">{content.title}</h1>

      {/* Description */}
      <p className="text-lg text-gray-700 mb-6 leading-relaxed">{content.description}</p>
    </div>
  );
};

export default SingleContentPage;
