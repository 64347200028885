import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';

function Calendar({ className, classNames, availableDates = [], selectedDate, onSelect, ...props }) {
  // Convert available dates into Date objects for comparison
  const availableDateObjects = availableDates.map(date => new Date(date));
  
  // Custom onSelect handler to block selection of unavailable dates
  const handleSelect = (date) => {
    console.log("cal date: ", date );
    onSelect(date); // Only call onSelect if the date is available
    // console.log("aval: ", availableDateObjects );
    // if (availableDateObjects.some(availableDate => date.toDateString() === availableDate.toDateString())) {
    // } else {
    //   console.log("got here? ")
    // }
  };

  return (
    <DayPicker
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_outside: 'text-muted-foreground opacity-50',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        day_available: 'bg-green-100 text-green-600', // Custom class for available dates
        ...classNames,
      }}
      modifiers={{
        available: availableDateObjects,
        // Disable all dates that are not in the availableDates array
        disabled: {
          notAvailable: date => !availableDateObjects.some(availableDate => 
            date.toDateString() === availableDate.toDateString()
          )
        }
      }}
      modifiersClassNames={{
        available: 'day_available', // Custom class to apply to available dates
        disabled: 'day_disabled',   // Custom class to style disabled dates
      }}
      onSelect={handleSelect} // Override onSelect to only allow available dates
      {...props}
    />
  );
}

Calendar.displayName = 'Calendar';

export { Calendar };
