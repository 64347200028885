import React, { useEffect, useState, useCallback } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import axiosInstance from '@/utils/axios';
import AccountNav from '@/components/ui/AccountNav';
import Perks from '@/components/ui/Perks';
import PhotosUploader from '@/components/ui/PhotosUploader';
import Spinner from '@/components/ui/Spinner';
// import DatePickerMultipleDates from '@/components/ui/DatePickerMultipleDates';
import DateTimePicker from '@/components/ui/DateTimePicker';

const PlacesFormPage = () => {
  const { id } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    address: '',
    category: '',
    description: '',
    perks: [],
    extraInfo: '',
    checkIn: '',
    checkOut: '',
    maxGuests: 10,
    price: 500,
    availableDates: [],
  });

  const {
    title,
    address,
    category,
    description,
    perks,
    extraInfo,
    maxGuests,
    price,
    availableDates,
  } = formData;

  const isValidPlaceData = () => {
    if (title.trim() === '') {
      toast.error("Title can't be empty!");
      return false;
    } else if (address.trim() === '') {
      toast.error("Address can't be empty!");
      return false;
    } else if (category.trim() === '') {
      toast.error("Category can't be empty!");
      return false;
    // } else if (addedPhotos.length < 1) {
    //   toast.error('Upload at least 5 photos!');
    //   return false;
    } else if (description.trim() === '') {
      toast.error("Description can't be empty!");
      return false;
    } else if (maxGuests < 1) {
      toast.error('At least one guest is required!');
      return false;
    } else if (maxGuests > 100) {
      toast.error("Max. guests can't be greater than 100");
      return false;
    }
    return true;
  };

  const handleFormData = (e) => {
    const { name, value, type } = e.target;
    if (type !== 'checkbox') {
      setFormData({ ...formData, [name]: value });
      return;
    }
    const updatedPerks = perks.includes(name)
      ? perks.filter((perk) => perk !== name)
      : [...perks, name];
    setFormData({ ...formData, perks: updatedPerks });
  };

  const savePlace = async (e) => {
    e.preventDefault();
    const cleanPhotos = addedPhotos.map((photo) => photo.replace('/uploads/', ''));
    const placeData = { ...formData, addedPhotos: cleanPhotos };

    if (isValidPlaceData()) {
      if (id) {
        console.log("updating place.");
        await axiosInstance.put('/places/update-place', { id, ...placeData });
      } else {
        await axiosInstance.post('/places/add-places', placeData);
      }
      toast.success("Successfuly Created a Farm.")
      setRedirect(true);
    }
  };

  const archivePlace = async () => {
    try {
      await axiosInstance.put(`/places/archive/${id}`);
      toast.success('Place archived successfully!');
      setRedirect(true);
    } catch (error) {
      toast.error('Failed to archive place!');
    }
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    axiosInstance.get(`/places/${id}`).then((response) => {
      const { place } = response.data;
      setIsArchived(place.isArchived || false);
      setFormData((prev) => ({ ...prev, ...place }));
      setAddedPhotos([...place.photos]);
      setLoading(false);
    });
  }, [id]);

  // Use useCallback to memoize setDateRange
  const setDateRange = useCallback((dates) => {
    setFormData((prevFormData) => ({ ...prevFormData, availableDates: dates }));
  }, [setFormData]);


  if (redirect) return <Navigate to="/account/places" />;
  if (loading) return <Spinner />;

  const preInput = (header, description) => (
    <>
      <h2 className="mt-4 text-2xl">{header}</h2>
      <p className="text-sm text-gray-500">{description}</p>
    </>
  );

  return (
    <>
      <AccountNav />
      <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-5 mb-5">
        <form onSubmit={savePlace}>
          {preInput('Name of Farm', 'the name of the farm. Should be short and catchy as in advertisement')}
          <input type="text" name="title" value={title} onChange={handleFormData} placeholder="Farm Name..." />

          {preInput('Address', 'address to this place')}
          <input type="text" name="address" value={address} onChange={handleFormData} placeholder="Address..." />

          {preInput('Category', 'Select the category for your place')}
          <select name="category" value={category} onChange={handleFormData} className="w-full mt-2 p-2 border rounded-md">
            <option value="" disabled>Select a category</option>
            <option value="vegetable-farm">Vegetable Farm</option>
            <option value="fruit-orchard">Fruit Orchard</option>
            <option value="dairy-farm">Dairy Farm</option>
            <option value="livestock-farm">Livestock Farm</option>
            <option value="poultry-farm">Poultry Farm</option>
            <option value="flower-farm">Flower Farm</option>
            <option value="herb-farm">Herb Farm</option>
            <option value="grain-farm">Grain Farm</option>
            <option value="aquaculture-farm">Aquaculture Farm</option>
            <option value="vineyard">Vineyard (Grape Farm)</option>
            <option value="organic-farm">Organic Farm</option>
            <option value="bee-farm">Bee Farm (Apiary)</option>
            <option value="mushroom-farm">Mushroom Farm</option>
            <option value="permaculture-farm">Permaculture Farm</option>
            <option value="hydroponic-farm">Hydroponic Farm</option>
            <option value="aquaponic-farm">Aquaponic Farm</option>
            <option value="tree-farm">Tree Farm (Christmas Trees, Timber)</option>
            <option value="specialty-crop-farm">Specialty Crop Farm (e.g., Lavender, Hemp)</option>
            <option value="mixed-farm">Mixed Farm (Combination of Livestock and Crops)</option>
            <option value="urban-farm">Urban Farm</option>
            <option value="homestead-farm">Homestead Farm (Small-Scale, Self-Sufficient Farming)</option>
          </select>

          {preInput('Photos', 'more = better')}
          <PhotosUploader addedPhotos={addedPhotos} setAddedPhotos={setAddedPhotos} />

          {preInput('Description', 'description of the place')}
          <textarea value={description} name="description" onChange={handleFormData} />

          {preInput('Educational Activities', 'select all the perks of your place')}
          <Perks selected={perks} handleFormData={handleFormData} />

          {preInput('Available Time Slots', 'select dates')}
       
          {/* <DatePickerMultipleDates
            selectedDates={availableDates}
            setDateRange={(dates) => setFormData({ ...formData, availableDates: dates })}
          /> */}
          <DateTimePicker
            selectedDates={formData.availableDates}  // Use availableDates
            setDateRange={setDateRange}
          />


          {preInput('Extra info', 'house rules, etc')}
          <textarea value={extraInfo} name="extraInfo" onChange={handleFormData} />

          {preInput('Number of guests & Price', 'Specify the maximum number of guests so that the client stays within the limit.')}
          <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-4">
            <div>
              <h3 className="mt-2 -mb-1">Max no. of students</h3>
              <input type="number" name="maxGuests" value={maxGuests} onChange={handleFormData} placeholder="1" />
            </div>
            <div>
              <h3 className="mt-2 -mb-1">Price per student</h3>
              <input type="number" name="price" value={price} onChange={handleFormData} placeholder="1" />
            </div>
          </div>

          <button className="mx-auto my-4 flex rounded-full bg-primary py-3 px-20 text-xl font-semibold text-white">
            Save
          </button>

          {id && !isArchived && (
            <button
              type="button"
              onClick={() => setShowArchiveModal(true)}
              className="mx-auto mt-4 flex rounded-full bg-red-600 py-3 px-20 text-xl font-semibold text-white"
            >
              Archive Place
            </button>
          )}
        </form>
      </div>

      {showArchiveModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">Do you really want to archive this place? This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowArchiveModal(false)} className="px-4 py-2 bg-gray-300 rounded-md">
                Cancel
              </button>
              <button onClick={archivePlace} className="px-4 py-2 bg-red-600 text-white rounded-md">
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlacesFormPage;
