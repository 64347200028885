import React, { useState } from 'react';

const TimeSlotSelector = ({ availableDates, onTimeSlotsChange }) => {
  const [selectedDateTime, setSelectedDateTime] = useState({ bookingDate: null, startTime: '', endTime: '' });

  const handleTimeSlotChange = (date, slot) => {
    const newSelection = { bookingDate: date, startTime: slot.startTime, endTime: slot.endTime };
    setSelectedDateTime(newSelection);
    onTimeSlotsChange(newSelection); // Pass the selected date and time slot back to the parent
  };

  // Function to format date in a human-readable format
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Function to format time in a human-readable format
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));

    // Format the time to a more readable format like "2:00 PM"
    return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
  };

  // Filter out dates that have no available time slots
  const filteredDates = availableDates.filter(({ timeSlots }) =>
    timeSlots.some((slot) => !slot.booked)
  );

  // Check if there are any available slots overall
  const hasAvailableSlots = filteredDates.length > 0;

  return (
    <div>
      {!hasAvailableSlots ? (
        <div className="text-red-500 mt-4">No available time slots for any date.</div>
      ) : (
        filteredDates.map(({ date, timeSlots }) => {
          // Filter available slots for each date
          const availableSlots = timeSlots.filter((slot) => !slot.booked);

          return (
            <div key={date} className="mb-6">
              <h5 className="text-md font-medium mb-2">{formatDate(date)}</h5>
              <ul className="space-y-2">
                {availableSlots.map((slot, index) => (
                  <li key={index} className="flex items-center">
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="timeSlot"
                        value={`${slot.startTime}-${slot.endTime}`}
                        onChange={() => handleTimeSlotChange(date, slot)}
                        checked={
                          selectedDateTime.bookingDate === date &&
                          selectedDateTime.startTime === slot.startTime &&
                          selectedDateTime.endTime === slot.endTime
                        }
                      />
                      <span className="text-gray-700">
                        {formatTime(slot.startTime)} - {formatTime(slot.endTime)}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          );
        })
      )}
    </div>
  );
};

export default TimeSlotSelector;
