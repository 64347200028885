// http.js
const getBaseUrl = () => {
    let url;
    // console.log("enviroment: ", process.env.NODE_ENV );
    switch (process.env.NODE_ENV) {
        case 'production':
            url = 'https://www.farmtox.com';
            // url = 'http://18.234.175.1';
            // url = 'http://localhost:8000';
            break;
        default:
            url = 'http://localhost:8000';

    }
    return url;
}

export default getBaseUrl;

// http.js
// const getBaseUrl = () => {
//     let url;
//     const env = import.meta.env;
//     console.log("getURL env: ", env.CLIENT_URL );

//     // Use env.MODE to check the mode ('development' or 'production')
//     switch (env.MODE) {
//         case 'production':
//             // Use VITE_API_URL if it's set, or default to localhost for testing
//             url = env.CLIENT_URL || 'http://localhost:8000';
//             break;
//         default:
//             url = 'http://localhost:8000';
//     }
//     return url;
// }

// export default getBaseUrl;
