import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import URL from '../utils/getUrl';

var url = URL();
const API_URL = url;

const ContentsPage = () => {
    const [contents, setContents] = useState([]); // State to store content data
    const [filteredContents, setFilteredContents] = useState([]); // State for filtered content
    const [loading, setLoading] = useState(true); // Set loading to true initially
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    useEffect(() => {
        // Fetch all content from the backend
        const getContents = async () => {
            try {
                const { data } = await axiosInstance.get('/content');
                setContents(data.contents); // Assume 'data.contents' is the array of content items
                setFilteredContents(data.contents); // Initialize filtered content
                setLoading(false);
            } catch (error) {
                console.log('Error: ', error);
                setLoading(false);
            }
        };
        getContents();
    }, []);

    useEffect(() => {
        // Filter the contents based on the search term
        const filtered = contents.filter(content => 
            content.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            content.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredContents(filtered);
    }, [searchTerm, contents]);

    if (loading) return <Spinner />;

    return (
        <div className="flex flex-col items-center w-full">
            <Link
                className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white m-3"
                style={{ marginTop: "100px" }}
                to={'/'}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19.5l-7.5-7.5 7.5-7.5"
                    />
                </svg>
                Back to home
            </Link>

            {/* Search Input */}
            <div className="w-full max-w-md mt-1">
                <input
                    type="text"
                    placeholder="Search content..."
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
            </div>

            {/* Display filtered contents */}
            <div className="w-full container mt-8">
                {filteredContents.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {filteredContents.map((content) => (
                            <div key={content._id} className="border p-4 rounded-md shadow-md">
                                <h2 className="text-xl font-semibold">{content.title}</h2>
                                <p
                                    className="text-sm text-gray-600 mt-2 overflow-hidden"
                                    style={{ maxHeight: '200px' }}
                                >
                                    {content.description}
                                </p>

                                {content.photos.length > 0 && (
                                    <img
                                        src={`${API_URL}/uploads/${content.photos[0]}`}
                                        alt={content.title}
                                        className="w-full h-48 object-cover rounded-md mt-4"
                                    />
                                )}

                                {content.place && (
                                    <p className="mt-2 text-sm text-gray-500">
                                        Associated with: <strong>{content.place.title}</strong>
                                    </p>
                                )}

                                <Link
                                    to={`/content/${content._id}`}
                                    className="mt-4 inline-block text-primary hover:underline"
                                >
                                    View Details
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No content available</p>
                )}
            </div>
        </div>
    );
};

export default ContentsPage;
