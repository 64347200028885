import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '@/utils/axios'; // Assuming axios is configured
import PhotosUploader from '@/components/ui/PhotosUploader';
import { toast } from 'react-toastify';
import AccountNav from '@/components/ui/AccountNav';

const CreateContentPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        photos: [],
        place: '',
    });
    const [places, setPlaces] = useState([]);
    const [addedPhotos, setAddedPhotos] = useState([]);

    const { title, description, place } = formData;

    // Fetch places for dropdown
    useEffect(() => {
        const fetchPlaces = async () => {
            try {
                const { data } = await axiosInstance.get('/places');
                setPlaces(data.places);
            } catch (error) {
                toast.error('Failed to fetch places');
            }
        };
        fetchPlaces();
    }, []);

    // Fetch content if ID is present (for editing)
    useEffect(() => {
        if (id) {
            setLoading(true);
            axiosInstance
                .get(`/content/${id}`)
                .then((res) => {
                    console.log("res: ", res);
                    const content = res.data.content;  // Access the content data properly
                    setFormData({
                        title: content.title,
                        description: content.description,
                        place: content.place || '',
                    });
                    setAddedPhotos(content.photos || []);
                    setLoading(false);
                })
                .catch(() => {
                    toast.error('Failed to load content');
                    setLoading(false);
                });
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const contentData = {
                title,
                description,
                photos: addedPhotos,
                place,
            };
            console.log("content: ", contentData );

            if (id) {
                contentData.id = id;
                // Update existing content
                await axiosInstance.put('/content/update-content', contentData);  // Use correct update endpoint
                toast.success('Content updated successfully');
            } else {
                // Create new content
                await axiosInstance.post('/content', contentData);
                toast.success('Content created successfully');
            }

            navigate('/content'); // Redirect after save
        } catch (error) {
            toast.error('Failed to save content');
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <>
        <AccountNav />
        <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto mt-5 mb-5">
            <div className="w-full max-w-3xl mx-auto p-6">
                <h1 className="text-3xl font-semibold mb-6">
                    {id ? 'Update Content' : 'Create New Content'}
                </h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-xl font-semibold text-gray-700">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={title}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-xl font-semibold text-gray-700">Description</label>
                        <textarea
                            name="description"
                            value={description}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                            rows="4"
                            required
                        ></textarea>
                    </div>

                    <div className="mb-4">
                        <label className="block text-xl font-semibold text-gray-700">Photos</label>
                        <PhotosUploader addedPhotos={addedPhotos} setAddedPhotos={setAddedPhotos} />
                    </div>

                    <div className="mb-4">
                        <label className="block text-xl font-semibold text-gray-700">Place (Optional)</label>
                        <select
                            name="place"
                            value={place}
                            onChange={handleChange}
                            className="mt-2 w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                        >
                            <option value="">No place selected</option>
                            {places.map((place) => (
                                <option key={place._id} value={place._id}>
                                    {place.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            className="inline-flex items-center justify-center px-10 py-3 rounded-full bg-primary text-white text-lg font-semibold hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
};

export default CreateContentPage;
