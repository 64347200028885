import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import { useAuth } from '../../hooks';
import AccountNav from '@/components/ui/AccountNav';

import SubscribersPage from './Subscribers';
import AdminBookingsPage from './AdminBookings';
import AdminUsers from './AdminUsers';

const Admin = () => {
    const [activeTab, setActiveTab] = useState('Subscribers'); // Manage active tab state

    const auth = useAuth();
    const { user } = auth;

    // Redirect non-admin users
    if (user?.role !== 'admin') {
        return <Navigate to="/" />;
    }

    return (
        <>
            <div className="flex flex-col items-center w-full">
                <AccountNav />

                {/* Tab Navigation */}
                <div className="w-full max-w-4xl mt-6">
                    <div className="flex justify-center space-x-6 border-b border-gray-300 pb-2">
                        <button
                            className={`relative px-6 py-3 text-lg font-semibold transition duration-300 ease-in-out focus:outline-none ${activeTab === 'Subscribers'
                                    ? 'text-primary-500 border-b-4 border-primary-500'
                                    : 'text-gray-500 hover:text-primary-500'
                                }`}
                            onClick={() => setActiveTab('Subscribers')}
                        >
                            Subscribers
                            {activeTab === 'Subscribers' && (
                                <div className="absolute inset-x-0 bottom-0 h-1 bg-primary-500 rounded-t-md"></div>
                            )}
                        </button>
                        <button
                            className={`relative px-6 py-3 text-lg font-semibold transition duration-300 ease-in-out focus:outline-none ${activeTab === 'Bookings'
                                    ? 'text-primary-500 border-b-4 border-primary-500'
                                    : 'text-gray-500 hover:text-primary-500'
                                }`}
                            onClick={() => setActiveTab('Bookings')}
                        >
                            Bookings
                            {activeTab === 'Bookings' && (
                                <div className="absolute inset-x-0 bottom-0 h-1 bg-primary-500 rounded-t-md"></div>
                            )}
                        </button>
                        <button
                            className={`relative px-6 py-3 text-lg font-semibold transition duration-300 ease-in-out focus:outline-none ${activeTab === 'Users'
                                    ? 'text-primary-500 border-b-4 border-primary-500'
                                    : 'text-gray-500 hover:text-primary-500'
                                }`}
                            onClick={() => setActiveTab('Users')}
                        >
                            Users
                            {activeTab === 'Users' && (
                                <div className="absolute inset-x-0 bottom-0 h-1 bg-primary-500 rounded-t-md"></div>
                            )}
                        </button>
                    </div>
                </div>


                {/* Tab Content (Placeholder) */}
                <div className="w-full px-4 mt-6 max-w-4xl">
                    {activeTab === 'Subscribers' && (
                        <SubscribersPage />
                    )}
                    {activeTab === 'Bookings' && (
                        <AdminBookingsPage />
                    )}
                    {activeTab === 'Users' && (
                        <AdminUsers />
                    )}
                </div>
            </div>
        </>
    );
};

export default Admin;
