import React from 'react';
import homeSchoolImg2 from '../../assets/HomeSchools2.jpg'; 

import { Link } from 'react-router-dom';

const HomeSchools = () => {
  return (
    <div className="relative flex flex-col lg:flex-row lg:items-center lg:justify-between">
      <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl lg:my-20">
        <div className="mb-16 lg:mb-0 lg:max-w-lg lg:pr-5">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Turn Your World
              <br className="hidden md:block" />
              into a Classroom
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
            Farm to X supports homeschooling families and co-ops by bringing the farm experience to you. Through tailored farm-based curriculum, hands-on projects, and interactive lessons, we connect home-based learners with real-world farming. Our resources integrate sustainable living and agriculture, empowering families to engage in farming from their own environments.
            <br/> <br />
            Farm to X supports homeschooling families and co-ops by bringing the farm and homesteading experience to you. Through tailored farm-based curriculum, hands-on projects, and interactive lessons, we connect home-based learners with real-world farming and sustainable living. Our resources empower families to embrace homesteading practices—growing food, raising animals, and cultivating self-sufficiency—right from their own environments
            </p>
          </div>
          <div className="flex items-center">
            <Link
              to="/homeSchools"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Learn more
              <svg
                className="inline-block w-3 ml-2"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden sm:block lg:w-1/2 lg:h-full lg:relative lg:flex-shrink-0">
        <img
          className="object-cover w-full h-full lg:h-full lg:rounded-none"
          src={homeSchoolImg2}
          alt=""
        />
      </div>
    </div>
  );
};

export default HomeSchools;
