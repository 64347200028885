import React from 'react';

import URL from '../../utils/getUrl';
var url = URL();
const API_URL = url;

const PlaceImg = ({ place, index = 0, className = null }) => {
  if (!place.photos?.length) {
    return '';
  }
  if (!className) {
    className = 'object-cover';
  }
  return <img src={`${API_URL}/uploads/${place.photos[index]}`} alt="" className={className} crossOrigin="anonymous"/>;
};

export default PlaceImg;

