import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import URL from '../utils/getUrl';

var url = URL();
const API_URL = url;

const ExperiencesPage = () => {
    const [listings, setListings] = useState([]); // State to store listing data
    const [filteredListings, setFilteredListings] = useState([]); // State for filtered listings
    const [loading, setLoading] = useState(true); // Set loading to true initially
    const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [selectedDate, setSelectedDate] = useState(''); // State for the selected date

    useEffect(() => {
        // Fetch all listings from the backend
        const getListings = async () => {
            try {
                const { data } = await axiosInstance.get('/places');
                console.log("data: ", data );
                setListings(data.places); // Assume 'data.places' is the array of listing items
                setFilteredListings(data.places); // Initialize filtered listings
                setLoading(false);
            } catch (error) {
                console.log('Error: ', error);
                setLoading(false);
            }
        };
        getListings();
    }, []);

    useEffect(() => {
        // Filter the listings based on the search term and selected date
        const filtered = listings.filter(listing => {
            // Match search term
            const matchesSearch = 
                listing.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                listing.description.toLowerCase().includes(searchTerm.toLowerCase());

            // Normalize and filter available dates by selectedDate (only comparing the date part)
            const matchesDate = selectedDate
                ? listing.availableDates.some(date =>
                    new Date(date).toISOString().slice(0, 10) === selectedDate
                )
                : true; // If no date selected, allow all listings

            return matchesSearch && matchesDate;
        });
        
        setFilteredListings(filtered);
    }, [searchTerm, selectedDate, listings]);

    // Reset search term and date
    const resetFilters = () => {
        setSearchTerm('');
        setSelectedDate('');
    };

    if (loading) return <Spinner />;

    return (
        <div className="flex flex-col items-center w-full">
            <Link
                className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white m-3"
                style={{ marginTop: "100px" }}
                to={'/'}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19.5l-7.5-7.5 7.5-7.5"
                    />
                </svg>
                Back to home
            </Link>

            {/* Search Input, Date Filter, and Reset Button inline */}
            <div className="w-full max-w-2xl mt-4 flex gap-4 items-center">
                <input
                    type="text"
                    placeholder="Search experiences..."
                    className="flex-1 p-2 border border-gray-300 rounded-md"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />

                <input
                    type="date"
                    className="p-2 border border-gray-300 rounded-md"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)} // Update selected date
                />

                {/* Reset Button */}
                <button
                    onClick={resetFilters}
                    className="p-2 bg-primary text-white rounded-md"
                >
                    Reset
                </button>
            </div>

            {/* Display filtered listings */}
            <div className="w-full container mt-8">
                {filteredListings.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
                        {filteredListings.map((listing) => (
                            <div key={listing._id} className="border p-4 rounded-md shadow-md">
                                <h2 className="text-xl font-semibold">{listing.title}</h2>
                                <p
                                    className="text-sm text-gray-600 mt-2 overflow-hidden"
                                    style={{ maxHeight: '200px' }}
                                >
                                    {listing.description}
                                </p>

                                {listing.photos.length > 0 && (
                                    <img
                                        src={`${API_URL}/uploads/${listing.photos[0]}`}
                                        alt={listing.title}
                                        className="w-full h-48 object-cover rounded-md mt-4"
                                    />
                                )}

                                {listing.price && (
                                    <p className="mt-2 text-sm text-gray-500">
                                        Price: <strong>${listing.price}</strong>
                                    </p>
                                )}

                                <Link
                                    to={`/place/${listing._id}`}
                                    className="mt-4 inline-block text-primary hover:underline"
                                >
                                    View Details
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No experiences available</p>
                )}
            </div>
        </div>
    );
};

export default ExperiencesPage;
