import React, { useRef } from "react";
import Slider from "react-slick";

const Carousel = () => {
  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };

  const settings = {
    dots: true,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    className: "slides",
    autoplay: true,
    autoplaySpeed: 3500
  };

  const slides = [
    {
      image: "assets/Field_trip-11.jpg",
      text: "Where farming meets innovation and education"
    },
    {
      image: "assets/Salads6-1.jpg",
      text: "Cultivating curiosity, from farm to everyday life"
    },
    {
      image: "assets/sTeam-12.jpg",
      text: "Grow your mind, from soil to society"
    },
    {
      image: "assets/WklyHarvest9.10.18-12.jpg",
      text: "Connecting farms, families, and futures"
    }
  ];

  return (
    <Slider ref={slider => (sliderRef = slider)} {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className="slide-container">
          <div
            className="slide-image"
            style={{
              backgroundImage: `url(${slide.image})`,
              height: '70vh', // Adjust height based on viewport
              width: '100vw', // Full width
              backgroundSize: 'cover', // Maintain full width and height while covering the container
              backgroundPosition: 'center', // Center the image
            }}
          >
            <div className="slide-overlay">
              <div className="text-box p-2">
                <h2>{slide.text}</h2>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
