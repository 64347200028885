import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from '../../../hooks';
import axiosInstance from '@/utils/axios';
import TimeSlotSelector from './TimeSlotSelctor';

const BookingWidget = ({ place }) => {
  const [selectedDateTime, setSelectedDateTime] = useState({ bookingDate: null, startTime: '', endTime: '' });
  const [bookingData, setBookingData] = useState({
    numOfGuests: 1,
    name: '',
    phone: '',
  });
  const [redirect, setRedirect] = useState('');
  const { user } = useAuth();

  const { numOfGuests, name, phone } = bookingData;
  const { _id: id, price } = place;

  // Autofill name from authenticated user
  useEffect(() => {
    if (user) {
      setBookingData((prev) => ({ ...prev, name: user.name }));
    }
  }, [user]);

  // Handle form input changes for booking data
  const handleBookingData = (e) => {
    setBookingData({
      ...bookingData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle booking submission
  const handleBooking = async () => {
    // User must be signed in to book place
    if (!user) {
      return setRedirect(`/login`);
    }

    console.log("Selected DateTime: ", selectedDateTime);
    
    // Validate the time slot and other booking details
    if (!selectedDateTime.bookingDate || !selectedDateTime.startTime || !selectedDateTime.endTime) {
      return toast.error('Please select a valid time slot');
    } else if (numOfGuests < 1) {
      return toast.error("No. of guests can't be less than 1");
    } else if (numOfGuests > place.maxGuests) {
      return toast.error(`Allowed max. no. of guests: ${place.maxGuests}`);
    } else if (name.trim() === '') {
      return toast.error("Name can't be empty");
    } else if (phone.trim() === '') {
      return toast.error("Phone can't be empty");
    }

    // Prepare the booking object
    const bookingRequest = {
      place: id,
      bookingDate: selectedDateTime.bookingDate,
      startTime: selectedDateTime.startTime,
      endTime: selectedDateTime.endTime,
      numOfGuests,
      name,
      phone,
      price,
    };

    try {
      // Make the booking request
      const response = await axiosInstance.post('/bookings', bookingRequest);

      // Redirect to bookings page on success
      const bookingId = response.data.booking._id;
      setRedirect(`/account/bookings/${bookingId}`);
      toast.success('Congratulations! Your booking has been reserved.');
    } catch (error) {
      toast.error('Something went wrong while booking.');
      console.log('Error:', error);
    }
  };

  // Redirect after successful booking
  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <div className="rounded-2xl bg-white p-4 shadow-xl">
      <div className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md mb-4" role="alert">
        <div className="flex">
          <div className="py-1">
            <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M10 12h2v2H8v-2h2zm0-8h2v6H8V4h2zm0 12h2v2H8v-2h2z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">Booking Reserve</p>
            <p className="text-sm">
              We will not charge you now. This booking only reserves your spot. The owner will reach out to you soon regarding billing.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center text-xl">
        Price: <span className="font-semibold">${price}</span> / per student
      </div>

      <div className="mt-4 rounded-2xl border">
        <div className="border-t py-3 px-4">
          <label>Select Time Slot: </label>
          <TimeSlotSelector availableDates={place.availableDates} onTimeSlotsChange={setSelectedDateTime} />
        </div>
        <div className="border-t py-3 px-4">
          <label>Number of students: </label>
          <input
            type="number"
            name="numOfGuests"
            placeholder={`Max. guests: ${place.maxGuests}`}
            min={1}
            max={place.maxGuests}
            value={numOfGuests}
            onChange={handleBookingData}
            className="w-full border rounded-md p-2 mt-2"
          />
        </div>
        <div className="border-t py-3 px-4">
          <label>Your full name: </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleBookingData}
            className="w-full border rounded-md p-2 mt-2"
          />
          <label className="mt-4 block">Phone number: </label>
          <input
            type="tel"
            name="phone"
            value={phone}
            onChange={handleBookingData}
            className="w-full border rounded-md p-2 mt-2"
          />
        </div>
      </div>
      <button
        onClick={handleBooking}
        className="primary mt-4 w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-200"
      >
        Book this place
      </button>
    </div>
  );
};

export default BookingWidget;
