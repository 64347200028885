import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';
import AccountNav from '@/components/ui/AccountNav';
import { useAuth } from '../../hooks';

import URL from '../utils/getUrl';
var url = URL();
const API_URL = url;

const ContentPage = () => {
    const [contents, setContents] = useState([]); // State to store content data
    const [loading, setLoading] = useState(true); // Set loading to true initially

    const auth = useAuth();
    const { user } = auth;
    // console.log("user: ", user );

    useEffect(() => {
        // Fetch all content from the backend
        const getContents = async () => {
            try {
                const { data } = await axiosInstance.get('/content/bookings-content');
                setContents(data.contents); // Assume 'data.contents' is the array of content items
                setLoading(false);
            } catch (error) {
                console.log('Error: ', error);
                setLoading(false);
            }
        };
        getContents();
    }, []);

    if (loading) return <Spinner />;

    return (
        <div className="flex flex-col items-center w-full">
            <AccountNav />
            {user?.role === 'admin' && (
                <Link
                    className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white"
                    to={'/content/new'}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                        />
                    </svg>
                    Add new content
                </Link>
            )}

            {/* Display all contents */}
            <div className="w-full container mt-8">
                {contents.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {contents.map((content) => (
                            <div key={content._id} className="border p-4 rounded-md shadow-md">
                                <h2 className="text-xl font-semibold">{content.title}</h2>
                                <p
                                    className="text-sm text-gray-600 mt-2 overflow-hidden"
                                    style={{ maxHeight: '200px' }}
                                >
                                    {content.description}
                                </p>

                                {content.photos.length > 0 && (
                                    <img
                                        src={`${API_URL}/uploads/${content.photos[0]}`}
                                        alt={content.title}
                                        className="w-full h-48 object-cover rounded-md mt-4"
                                    />
                                )}

                                {content.place && (
                                    <p className="mt-2 text-sm text-gray-500">
                                        Associated with: <strong>{content.place.title}</strong>
                                    </p>
                                )}

                                <Link
                                    to={`/content/${content._id}`}
                                    className="mt-4 inline-block text-primary hover:underline"
                                >
                                    View Details
                                </Link>

                                {user?.role === 'admin' && (
                                    <Link
                                        to={`/content/new/${content._id}`}
                                        className="mt-4 ml-4 text-gray-500 hover:underline"
                                    >
                                        Update Content
                                    </Link>
                                )}
                            </div>
                        ))}
                    </div>
                  
                ) : (
                    <p>No content available</p>
                )}
            </div>
        </div>
    );
};

export default ContentPage;
