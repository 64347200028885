import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axiosInstance from '@/utils/axios';
import { useAuth } from '../../hooks';
import Spinner from '@/components/ui/Spinner';
import { toast } from 'react-toastify';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const auth = useAuth();
  const { user } = auth;

  // Redirect non-admin users
  if (user?.role !== 'admin') {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await axiosInstance.get('/user/admin/get-users');
        setUsers(data.data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching users: ', error);
        setLoading(false);
      }
    };
    getUsers();
  }, []);

  // Function to handle role change and send update request to the server
  const handleRoleChange = async (userId, newRole) => {
    try {
      await axiosInstance.put(`/user/admin/update-role`, { userId, role: newRole });
      console.log("updated!");
      toast.success("Updated User Role!");
      // Update the local state with the new role
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.log('Error updating user role: ', error);
    }
  };

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const downloadCSV = () => {
    const headers = ['ID,Email,Role,Created At\n'];
    const rows = users.map((user) =>
      `${user._id},"${user.email}","${user.role}","${new Date(user.createdAt).toISOString()}"`
    );

    const csvContent = headers + rows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <Spinner />;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Users List</h2>
        <button
          onClick={downloadCSV}
          className="rounded bg-primary text-white py-2 px-4 hover:bg-green-700"
        >
          Download CSV
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg">
        {users.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Role
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {users.map((user, i) => (
                  <tr key={i}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                      <span>{user.name}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <select
                        value={user.role}
                        onChange={(e) => handleRoleChange(user._id, e.target.value)}
                        className="rounded border-gray-300 py-2 px-4"
                      >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="demo">Demo</option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="pt-6 text-2xl font-semibold">No users found!</h3>
            <p>It seems like there are no users yet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
