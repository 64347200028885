import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '@/components/ui/Spinner';

const SchoolsPage = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Data fetching can be added here
    }, []);

    if (loading) return <Spinner />;

    return (
        <div className="flex flex-col items-center w-full" style={{ marginTop: "100px" }}>
            <Link
                className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white m-3"
                to={'/'}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19.5l-7.5-7.5 7.5-7.5"
                    />
                </svg>
                Back to home
            </Link>
            <div className="w-full container">
                <div
                    className="relative"
                    style={{
                        background: 'linear-gradient(135deg, #3B5D34 0%, #A4C639 50%, #A7C7E7 100%)',
                    }}
                >
                    <div className="absolute inset-x-0 bottom-0">
                        <svg
                            viewBox="0 0 224 12"
                            fill="currentColor"
                            className="w-full -mb-1 text-white"
                            preserveAspectRatio="none"
                        >
                            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
                        </svg>
                    </div>
                    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                            <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                                How Farm to X
                                <br className="hidden md:block" />
                                Works with Schools
                            </h2>
                            <p className="mb-6 text-base text-indigo-100 md:text-lg">
                                Our farm-to-school initiatives connect students with sustainable farming practices and food production, enriching their education through hands-on experiences and real-world learning.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="text-center mb-10">
                        <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                            What We Offer:
                        </h2>
                    </div>

                    <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-2">
                        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                            <h3 className="text-2xl font-semibold text-green-600 mb-4">Onsite Farm Programs</h3>
                            <p className="text-gray-700 leading-relaxed">
                                We partner with schools to create comprehensive farm-to-school experiences where students learn about different growing methods. They’ll explore traditional soil farming, hydroponic and aquaponic systems, permaculture principles, and even mushroom cultivation. Students get a well-rounded, hands-on introduction to the future of farming.
                            </p>
                        </div>

                        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                            <h3 className="text-2xl font-semibold text-green-600 mb-4">Classroom Integration</h3>
                            <p className="text-gray-700 leading-relaxed">
                                Our curriculum incorporates multiple growing systems into lessons that align with core subjects like science and technology. Students will investigate plant biology, ecosystem interactions, and sustainable practices using examples from soil-based farms, hydroponic gardens, and more.
                            </p>
                        </div>

                        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                            <h3 className="text-2xl font-semibold text-green-600 mb-4">Farm Experiences</h3>
                            <p className="text-gray-700 leading-relaxed">
                                Bring students to farms that showcase a variety of agricultural techniques. They’ll engage with experts who explain how permaculture, aquaponics, and mushroom farming work. Each field trip is an opportunity to experience firsthand the diversity of farming systems and how they contribute to the local food ecosystem.
                            </p>
                        </div>

                        <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                            <h3 className="text-2xl font-semibold text-green-600 mb-4">Customized Curriculum</h3>
                            <p className="text-gray-700 leading-relaxed">
                                We work with educators to design lesson plans that incorporate the exploration of different growing methods into the classroom. Whether it’s building an aquaponic or hydronic system in a classroom or designing outdoor learning gardens, we integrate experiential learning into the curriculum.
                            </p>
                        </div>

                        <div className="p-6 bg-gray-50 rounded-lg shadow-md sm:col-span-2">
                            <h3 className="text-2xl font-semibold text-green-600 mb-4">Long-Term Planning & Funding</h3>
                            <p className="text-gray-700 leading-relaxed">
                                Farm to X doesn’t just deliver educational experiences; we help schools craft long-term plans to integrate sustainable agriculture into their curriculum. We provide guidance on how to secure funding for ongoing projects and help identify grant opportunities to support your school’s agricultural initiatives. Our team can also assist in writing grants and securing funds to ensure your program thrives year after year.
                            </p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default SchoolsPage;
