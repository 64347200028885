import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '../../../hooks';
import { Avatar, AvatarImage } from '@radix-ui/react-avatar';

// Directly reference the image in the public folder
const imgSrc = './farm2x.png';

export const Header = () => {
  const auth = useAuth();
  const location = useLocation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [hasShadow, setHasShadow] = useState(false);

  const { user } = auth;

  const handleScroll = () => {
    const shouldHaveShadow = window.scrollY > 0;
    setHasShadow(shouldHaveShadow);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMobileMenuOpen(false); // Close menu after clicking
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    if (location.pathname === '/') {
      setShowNav(true);
    } else {
      setShowNav(false);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <header
      className={`fixed top-0 z-10 flex w-screen justify-center bg-white py-4 ${
        hasShadow ? 'shadow-md' : ''
      }`}
    >
      <div className="flex justify-between w-screen max-w-screen-xl px-10">

        <a href="/" className="flex items-center gap-1">
          <img style={{ height: "60px"}} src={imgSrc} alt="Farm Logo" />
        </a>

        {showNav && (
          <>
            <div className="block md:hidden">
              <button
                className="p-2 focus:outline-none"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              </button>

              {mobileMenuOpen && (
                <nav className="absolute top-16 right-0 w-full bg-white shadow-md flex flex-col items-start space-y-2 px-4 py-4 md:hidden">
                  <button
                    onClick={() => scrollToSection('about-us')}
                    className="text-[#3B5D34] hover:text-[#3B5D34] w-full text-left"
                  >
                    About Us
                  </button>
                  <button
                    onClick={() => scrollToSection('listings')}
                    className="text-[#3B5D34] hover:text-[#3B5D34] w-full text-left"
                  >
                    Experiences
                  </button>
                  <button
                    onClick={() => scrollToSection('schools')}
                    className="text-[#3B5D34] hover:text-[#3B5D34] w-full text-left"
                  >
                    Farm to School
                  </button>
                  <button
                    onClick={() => scrollToSection('home-schools')}
                    className="text-[#3B5D34] hover:text-[#3B5D34] w-full text-left"
                  >
                    Farm to Home
                  </button>
                </nav>
              )}
            </div>

            <nav className="hidden md:flex items-center space-x-6 text-lg">
              <button
                onClick={() => scrollToSection('about-us')}
                className="text-[#3B5D34] hover:text-[#3B5D34] bg-transparent border-none p-0 m-0 cursor-pointer"
              >
                About Us
              </button>
              <button
                    onClick={() => scrollToSection('listings')}
                    className="text-[#3B5D34] hover:text-[#3B5D34] bg-transparent border-none p-0 m-0 cursor-pointer"
                  >
                    Experiences
                  </button>
              <button
                onClick={() => scrollToSection('schools')}
                className="text-[#3B5D34] hover:text-[#3B5D34] bg-transparent border-none p-0 m-0 cursor-pointer"
              >
                Farm to School
              </button>
              <button
                onClick={() => scrollToSection('home-schools')}
                className="text-[#3B5D34] hover:text-[#3B5D34] bg-transparent border-none p-0 m-0 cursor-pointer"
              >
                Farm to Home
              </button>
            </nav>
          </>
        )}

        <Link
          to={user ? '/account' : '/login'}
          className="w-50 flex h-full items-center gap-2 rounded-full border-gray-300 py-1 px-2 md:border"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="hidden h-6 w-6 md:block"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>

          <div className="z-10 h-[35px] w-[35px] overflow-hidden rounded-full">
            {user ? (
              <Avatar>
                {user?.picture ? (
                  <AvatarImage src={user.picture} className="h-full w-full" />
                ) : (
                  <AvatarImage
                    src="https://res.cloudinary.com/rahul4019/image/upload/v1695133265/pngwing.com_zi4cre.png"
                    className="h-full w-full"
                  />
                )}
              </Avatar>
            ) : (
              <svg
                fill="#858080"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="796 796 200 200"
                enableBackground="new 796 796 200 200"
                xmlSpace="preserve"
                stroke="#858080"
                className="h-8 w-8"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M896,796c-55.14,0-99.999,44.86-99.999,100c0,55.141,44.859,100,99.999,100c55.141,0,99.999-44.859,99.999-100 C995.999,840.86,951.141,796,896,796z M896.639,827.425c20.538,0,37.189,19.66,37.189,43.921c0,24.257-16.651,43.924-37.189,43.924 s-37.187-19.667-37.187-43.924C859.452,847.085,876.101,827.425,896.639,827.425z M896,983.86 c-24.692,0-47.038-10.239-63.016-26.695c-2.266-2.335-2.984-5.775-1.84-8.82c5.47-14.556,15.718-26.762,28.817-34.761 c2.828-1.728,6.449-1.393,8.91,0.828c7.706,6.958,17.316,11.114,27.767,11.114c10.249,0,19.69-4.001,27.318-10.719 c2.488-2.191,6.128-2.479,8.932-0.711c12.697,8.004,22.618,20.005,27.967,34.253c1.144,3.047,0.425,6.482-1.842,8.817 C943.037,973.621,920.691,983.86,896,983.86z"></path>
                </g>
              </svg>
            )}
          </div>
        </Link>
      </div>
      <br className="border border-gray-600" />
    </header>
  );
};

export default Header;
