import React from 'react';
import { Link } from 'react-router-dom';

export const Schools = () => {
  return (
    <div
      className="relative"
      style={{
        background: 'linear-gradient(135deg, #3B5D34 0%, #A4C639 50%, #A7C7E7 100%)',
      }}
    >
      <div className="absolute inset-x-0 bottom-0">
        <svg
          viewBox="0 0 224 12"
          fill="currentColor"
          className="w-full -mb-1 text-white"
          preserveAspectRatio="none"
        >
          <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
        </svg>
      </div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="relative max-w-2xl mx-auto sm:text-center">

          <h2 className="mb-6 mt-5 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
            Bringing the Farm
            <br className="hidden md:block" />
            to Your Classroom
          </h2>
          <p className="mb-6 text-base text-indigo-100 md:text-lg">
            Farm to X collaborates with schools to design and implement farm-to-school programs that provide experiential learning opportunities. From cultivating school gardens to organizing farm visits, we enrich the curriculum with hands-on activities that teach students about food production, sustainability, and the broader agricultural industry. These programs connect students to farming in ways that inspire curiosity and build practical skills.
          </p>
        </div>

        <div className="flex justify-center">
          <Link
            to="/schools"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
          >
            Learn more
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Schools;
