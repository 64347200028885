import React from 'react';

const PerksWidget = ({ perks }) => {
  return (
    <div className="mt-8">
      <hr className="mb-8 border-t border-gray-200" />
      <p className="text-3xl font-bold text-gray-800">What this place offers</p>

      <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4">
        <div className="flex items-center gap-3 p-4 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 text-blue-600"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
          </svg>
          <span className={`${perks?.includes('farmTour') ? 'text-gray-800' : 'line-through text-gray-400'}`}>
            Farm Tour
          </span>
        </div>

        <div className="flex items-center gap-3 p-4 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 text-green-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 22c0-5.523-4.477-10-10-10 0-5.523 4.477-10 10-10 5.523 0 10 4.477 10 10s-4.477 10-10 10zm0 0c-2.761 0-5-2.239-5-5"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 7v8m-4-4h8"
            />
          </svg>
          <span className={`${perks?.includes('cropPlanting') ? 'text-gray-800' : 'line-through text-gray-400'}`}>
            Crop Planting
          </span>
        </div>

        <div className="flex items-center gap-3 p-4 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 text-red-600"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
          </svg>
          <span className={`${perks?.includes('animalCare') ? 'text-gray-800' : 'line-through text-gray-400'}`}>
            Animal Care
          </span>
        </div>

        <div className="flex items-center gap-3 p-4 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 text-yellow-600"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
          </svg>
          <span className={`${perks?.includes('farmToHome') ? 'text-gray-800' : 'line-through text-gray-400'}`}>
            Farm to Home
          </span>
        </div>

        <div className="flex items-center gap-3 p-4 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 text-purple-600"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
          </svg>
          <span className={`${perks?.includes('foodProcessing') ? 'text-gray-800' : 'line-through text-gray-400'}`}>
            Food Processing
          </span>
        </div>

        <div className="flex items-center gap-3 p-4 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-7 w-7 text-teal-600"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
          </svg>
          <span className={`${perks?.includes('farmTechnology') ? 'text-gray-800' : 'line-through text-gray-400'}`}>
            Farm Technology
          </span>
        </div>
      </div>
    </div>
  );
};

export default PerksWidget;
