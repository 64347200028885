import React, { useState, useEffect } from 'react';
import axiosInstance from '@/utils/axios';
import Spinner from '@/components/ui/Spinner';

const AdminBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingsPerPage] = useState(10); // Show 10 bookings per page

  useEffect(() => {
    const getBookings = async () => {
      try {
        const { data } = await axiosInstance.get('/bookings/admin-bookings');
        console.log("data ", data.bookings );
        setBookings(data.bookings); // Assuming API returns an array of bookings
        setLoading(false);
      } catch (error) {
        console.log('Error fetching bookings: ', error);
        setLoading(false);
      }
    };
    getBookings();
  }, []);

  // Pagination logic
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = bookings.slice(indexOfFirstBooking, indexOfLastBooking);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(bookings.length / bookingsPerPage)));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  // Download CSV function
  const downloadCSV = () => {
    const headers = ['User,Place,Booking Date,Start Time,End Time,Price,Guests\n'];
    const rows = bookings.map((booking) =>
      `${booking.user.email},${booking.place.title},${new Date(booking.bookingDate).toLocaleDateString('en-US')},${booking.timeSlot.startTime},${booking.timeSlot.endTime},${booking.price},${booking.numOfGuests}`
    );

    const csvContent = headers + rows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'bookings.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <Spinner />;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Bookings</h2>
        <button
          onClick={downloadCSV}
          className="rounded bg-primary text-white py-2 px-4 hover:bg-green-700"
        >
          Download CSV
        </button>
      </div>

      <div className="bg-white shadow-md rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Place</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Guests</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time Slot</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {currentBookings.map((booking, i) => (
                <tr key={i}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{booking.user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{booking.place.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{booking.numOfGuests}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(booking.bookingDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {`${booking.timeSlot.startTime} - ${booking.timeSlot.endTime}`}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${booking.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4 flex justify-center items-center p-3">
          <Pagination
            bookingsPerPage={bookingsPerPage}
            totalBookings={bookings.length}
            paginate={paginate}
            currentPage={currentPage}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </div>
      </div>
    </div>
  );
};

// Pagination Component
const Pagination = ({ bookingsPerPage, totalBookings, paginate, currentPage, nextPage, prevPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBookings / bookingsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="flex items-center space-x-2">
      <button
        onClick={prevPage}
        className="px-3 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-md"
        disabled={currentPage === 1}
      >
        Previous
      </button>

      <ul className="inline-flex space-x-1">
        {pageNumbers.map((number) => (
          <li key={number}>
            <button
              onClick={() => paginate(number)}
              className={`px-3 py-2 leading-tight ${number === currentPage ? 'bg-primary-500 text-white' : 'bg-white border-gray-300 text-gray-500'} border hover:bg-gray-200 rounded-md`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>

      <button
        onClick={nextPage}
        className="px-3 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-md"
        disabled={currentPage === pageNumbers.length}
      >
        Next
      </button>
    </nav>
  );
};

export default AdminBookings;
