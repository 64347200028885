import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '@/components/ui/Spinner';

const HomeSchoolsPage = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch data or perform other tasks here
    }, []);

    if (loading) return <Spinner />;

    return (
        <>
        <div className="flex flex-col items-center w-full" style={{ marginTop: "100px" }}>
          <Link
            className="inline-flex gap-1 rounded-full bg-primary py-2 px-6 text-white m-3"
            to={'/'}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19.5l-7.5-7.5 7.5-7.5"
              />
            </svg>
            Back to home
          </Link>

          <div className="w-full container">
            <div
              className="relative"
              style={{
                background: 'linear-gradient(135deg, #3B5D34 0%, #A4C639 50%, #A7C7E7 100%)',
              }}
            >
              <div className="absolute inset-x-0 bottom-0">
                <svg
                  viewBox="0 0 224 12"
                  fill="currentColor"
                  className="w-full -mb-1 text-white"
                  preserveAspectRatio="none"
                >
                  <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
                </svg>
              </div>
              <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                  <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                      How Farm to X Works with 
                    <br className="hidden md:block" />
                      Homeschooling Families and Groups
                  </h2>
                  <p className="mb-6 text-base text-indigo-100 md:text-lg">
                  Farm to X offers homeschooling families the chance to engage with a wide range of farming systems in a flexible, immersive way. We know that real-world learning is key to homeschooling, and our programs provide diverse, hands-on experiences that cater to different learning styles and interests.                  </p>
                </div>
              </div>
            </div>

            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
              <div className="text-center mb-10">
                <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  What We Offer:
                </h2>
              </div>

              <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-2">
                <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                  <h3 className="text-2xl font-semibold text-green-600 mb-4">Farm Experiences</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Homeschoolers can visit local farms to explore various growing systems, including traditional soil farming, hydroponics, aquaponics, permaculture, and mushroom cultivation. These field trips offer hands-on activities, from harvesting vegetables to understanding how aquaponics integrates fish and plants in a sustainable cycle. Each experience is tailored to meet educational objectives while fostering curiosity and a love for nature.
                  </p>
                </div>

                <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                  <h3 className="text-2xl font-semibold text-green-600 mb-4">Customizable Learning Experiences</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Whether you’re exploring permaculture, building a small-scale hydroponic system, or growing mushrooms at home, we offer customizable programs that meet the unique needs of homeschoolers. Our diverse farming systems provide endless opportunities for inquiry-based learning, helping students connect with the food they grow and eat.
                  </p>
                </div>

                <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                  <h3 className="text-2xl font-semibold text-green-600 mb-4">Bringing the Farm to You</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Can’t make it to a farm? Farm to X brings the learning to you with home-based kits and virtual experiences that cover everything from soil science to aquaponics. With instructional videos and hands-on activities, students can set up their own hydroponic or mushroom-growing systems at home, expanding their understanding of sustainable food production.
                  </p>
                </div>

                <div className="p-6 bg-gray-50 rounded-lg shadow-md">
                  <h3 className="text-2xl font-semibold text-green-600 mb-4">Community Building</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Homeschooling is about connecting with others, and Farm to X fosters community through group workshops and collaborative farming days. Families can learn together through projects like permaculture garden design or mushroom cultivation, deepening their understanding of regenerative practices while building strong community bonds.
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>
        </>
    );
};

export default HomeSchoolsPage;
